<template>
  <div class="sign-in" style="padding: 0 50px">
    <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">

      <a-col :span="24" :md="12" :lg="12" :xl="12">
        <h5 style="color: #63b4ff">支持主流中心化交易所</h5>
        <h2>数字货币合约跟单交易助手</h2>
        <div>
          <span>没有交易员盈利抽成，没有跟单席位限制，支持跨交易所跟单。对接交易所官方API，秒级跟单。</span>
        </div>
        <div>
          <span>不推荐，不引导。只做纯粹的第三方合约交易工具。</span>
        </div>
        <div>
          <span>合约交易有风险，请谨慎使用。支持虚拟盘试单！</span>
        </div>
        <router-link to="/sign-in" class="nav-link">
          <a-button type="primary" :size="large"
                    style="margin-top: 25px;width: 30%;height: 50px;background-color: #63b4ff;border: #63b4ff">
            免 费 使 用
          </a-button>
        </router-link>
      </a-col>

      <a-col :span="24" :md="12" :lg="12" :xl="12" class="head-img">
        <img src="images/index-1.png" alt="">
      </a-col>

    </a-row>
    <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 40px">
      <a-row style="text-align: center">
        <h6 style="color: #63b4ff">WHAT WE DO</h6>
        <h3 style="margin: 0">打破交易所信息壁垒</h3>
        <h3 style="margin: 0">拒绝被无良带单员割韭菜</h3>
        <h3 style="margin: 0">实现合约跟单自由</h3>
      </a-row>
      <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 55px">
        <a-col :span="24" :md="8" :lg="8" :xl="8" style="text-align: right">
          <a-row type="flex" :gutter="[24,24]">
            <a-col :span="24" :md="16" :lg="16" :xl="16" style="text-align: right">
              <h5 style="color: #63b4ff">选择交易员</h5>
              <p>
                在交易所或其他支持的平台选择合适的交易员，确保交易员的风控能力和交易能力。
              </p>
            </a-col>
            <a-col :span="24" :md="8" :lg="8" :xl="8" style="display: flex; justify-content: flex-start;">
              <a-icon type="idcard" theme="twoTone" style="fontSize:30px;"/>
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="[24,24]">
            <a-col :span="24" :md="16" :lg="16" :xl="16" style="text-align: right">
              <h5 style="color: #63b4ff">设置固定IP</h5>
              <p>
                只有在该IP下才能进行交易，确保你的账户安全，以及高速的交易频率。
              </p>
            </a-col>
            <a-col :span="24" :md="8" :lg="8" :xl="8" style="display: flex; justify-content: flex-start;">
              <a-icon type="thunderbolt" theme="twoTone" style="fontSize:30px;"/>
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="[24,24]">
            <a-col :span="24" :md="16" :lg="16" :xl="16" style="text-align: right">
              <h5 style="color: #63b4ff">配置跟单API</h5>
              <p>
                在交易所生成有交易权限的API，并配置固定IP,填入跟单猿API设置。
              </p>
            </a-col>
            <a-col :span="24" :md="8" :lg="8" :xl="8" style="display: flex; justify-content: flex-start;">
              <a-icon type="api" theme="twoTone" style="fontSize:30px"/>
            </a-col>
          </a-row>
        </a-col>
        <a-col :span="24" :md="8" :lg="8" :xl="8" style="display: flex; justify-content: center;">
          <img src="images/person-9ZQT8LM.png" alt="" style="width: 45%">
          <img src="images/phone-9ZQT8LM.png" alt="" style="width: 40%">
        </a-col>
        <a-col :span="24" :md="8" :lg="8" :xl="8" style="text-align: left">
          <a-row type="flex" :gutter="[24,24]">
            <a-col :span="24" :md="8" :lg="8" :xl="8" style="display: flex; justify-content: flex-end;">
              <a-icon type="project" theme="twoTone" style="fontSize:30px;"/>
            </a-col>
            <a-col :span="24" :md="16" :lg="16" :xl="16" style="text-align: left">
              <h5 style="color: #63b4ff">创建跟单任务</h5>
              <p>
                填入交易员所在平台的ID，选择用于此次跟单的交易API，即可开始跟单。
              </p>
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="[24,24]">
            <a-col :span="24" :md="8" :lg="8" :xl="8" style="display: flex; justify-content: flex-end;">
              <a-icon type="profile" theme="twoTone" style="fontSize:30px;"/>
            </a-col>
            <a-col :span="24" :md="16" :lg="16" :xl="16" style="text-align: left">
              <h5 style="color: #63b4ff">跟单明细查询</h5>
              <p>
                交易员所有交易记录，以及账户的跟单记录全都可以在线查看。
              </p>
            </a-col>
          </a-row>
          <a-row type="flex" :gutter="[24,24]">
            <a-col :span="24" :md="8" :lg="8" :xl="8" style="display: flex; justify-content: flex-end;">
              <a-icon type="dollar" theme="twoTone" style="fontSize:30px;"/>
            </a-col>
            <a-col :span="24" :md="16" :lg="16" :xl="16" style="text-align: left">
              <h5 style="color: #63b4ff">交易可控</h5>
              <p>
                在跟单的过程中，你可以随时在交易所进行手动止盈止损操作，不会影响到跟单，当交易员有新的交易时，跟单猿依然会自动跟进。
              </p>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-row>
    <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" style="margin-top: 120px;margin-bottom: 60px">
      <a-row style="text-align: center">
        <h6 style="color: #63b4ff">GET BEST TOOLS FOR MORE USDT</h6>
        <h1 style="margin: 0">从现在开始</h1>
        <h1 style="margin: 0">让全世界顶级交易员为你打工</h1>
<!--        <div><span>注册即送100USDT盈利额度</span></div>-->
        <router-link to="/sign-in" class="nav-link">
          <a-button type="primary" :size="large"
                    style="margin-top: 25px;width: 30%;height: 50px;background-color: #63b4ff;border: #63b4ff">
            开 始 跟 单
          </a-button>
        </router-link>
        <div><img src="images/world-map.png" alt="" style="width: 40%;margin-top: 50px"></div>
      </a-row>

    </a-row>
  </div>
</template>

<script>
export default {
  name: "Index"
}
</script>

<style scoped>


.head-img {
  overflow: hidden; /* 超出部分隐藏 */
}

.sign-in .head-img img {
  width: 150%;
  display: block;
  transform: translateX(-150px);
}
</style>